import { useNavigation } from "@react-navigation/native";
import moment from "moment";
import { Fragment, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { PopupFactory } from "..";
import {
  Calendar2Icon,
  ChevronRight,
  MessageQuestionIcon,
  Profile2UserIcon,
} from "../../assets/icons";
import { usePopup } from "../PopupFactory";
import ModalScreeningOption from "../ModalScreeningOptions";
import { checkCode } from "../../services/screening";

const Data = ({ Icon, label }) => {
  return (
    <View style={[styles.flexRow, { gap: 6 }]}>
      <View style={styles.icon}>
        <Icon width={14} height={14} fill="#000" />
      </View>
      <Text style={styles.data}>{label}</Text>
    </View>
  );
};

const SurveyCard = ({ data, additionalContentId, isScreening, role, isQuick, answerIds }) => {
  const {
    quizId,
    cover,
    title,
    isAnswered,
    totalPertanyaan,
    totalAnswered,
    created,
    totalResponden
  } = data;
  const navigation = useNavigation();
  const [showCover, setShowCover] = useState(true);
  const { toggle, visible } = usePopup();

  const isScreeningLimit = false;
  //   isScreening2
  // } = data;
  const [screeningOptionModal, setScreeningOptionModal] = useState(false);

  return (
    <Fragment>
      <TouchableOpacity
        onPress={() => {
          if (isScreeningLimit) {
            toggle();
            return;
          }
          
          if (role === "Petugas" && isScreening === "1") {
            setScreeningOptionModal(true);
            return;
          }

          if (isAnswered) {
            checkCode(additionalContentId, quizId)
                    .then((res) => {
                      const isLocked = res.data;
                      if (isLocked) {
                        return navigation.navigate(
                          "LockedScreening",
                          { quizId, additionalContentId, isScreening }
                        );
                      }

                      return navigation.navigate(
                          isScreening === "0" ? "SurveyResult" : "ScreeningResult",
                          { quizId, additionalContentId, isScreening }
                        );
                    });
            return;
          }
          navigation.navigate("SurveyDetail", { quizId, additionalContentId, isScreening });
        }}
      >
        <View style={styles.container}>
          {cover && showCover && (
            <Image
              source={{ uri: `https://fammi.ly/uploads/quiz/images/${cover}` }}
              style={styles.image}
              alt="cover"
              onError={() => setShowCover(false)}
            />
          )}
          <View style={styles.content}>
            <View style={styles.body}>
              <Text style={styles.title}>{title}</Text>
              <View style={[styles.flexRow, { gap: 12 }]}>
                <Data
                  Icon={MessageQuestionIcon}
                  label={
                    totalPertanyaan + (totalAnswered > 0 ? "" : " Pertanyaan")
                  }
                />
                <Data
                  Icon={Calendar2Icon}
                  label={moment(created).format("DD MMMM YYYY")}
                />
                {totalAnswered > 0 && (
                  <Data
                    Icon={Profile2UserIcon}
                    label={totalAnswered + " Mengisi"}
                  />
                )}
              </View>
            </View>
            <View
              style={[
                styles.footer,
                styles.flexRow,
                { justifyContent: "space-between" },
              ]}
            >
              <Text
                style={[
                  styles.footerTx,
                  { color: isAnswered ? "#4EB826" : "#999" },
                ]}
              >
                {
                  role === "Petugas" &&
                  isScreening === "1" &&
                  `${totalResponden} Responden`
                }
                {
                  isQuick &&
                  (answerIds?.indexOf(quizId.substring(0, 6)) < 0 || !answerIds) &&
                  `Kamu belum mengisi`
                }
                {
                  (role !== "Petugas" || isScreening === "0")&&
                  !isQuick &&
                  `Kamu ${isAnswered ? "sudah" : "belum"} mengisi`
                }
              </Text>
              <TouchableOpacity
                style={[styles.flexRow, { gap: 10 }]}
                onPress={() => {
                  if (role === "Petugas" && isScreening === "1") {
                    setScreeningOptionModal(true);
                    return;
                  }

                  if (isAnswered) {

                    checkCode(additionalContentId, quizId)
                    .then((res) => {
                      const isLocked = res.data;
                      if (isLocked) {
                        return navigation.navigate(
                          "LockedScreening",
                          { quizId, additionalContentId, isScreening }
                        );
                      }

                      return navigation.navigate(
                          isScreening === "0" ? "SurveyResult" : "ScreeningResult",
                          { quizId, additionalContentId, isScreening }
                        );
                    });

                    return;
                  }

                  if (isScreeningLimit) {
                    toggle();
                  }
                  
                  navigation.navigate("SurveyDetail", {
                    quizId,
                    additionalContentId,
                    isScreening
                  });
                }}
              >
                <Text style={[styles.footerTx, { color: "#6724E2" }]}>
                  {isAnswered ? "Lihat Hasil" : "Isi Sekarang"}
                </Text>
                <ChevronRight fill="#000" />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </TouchableOpacity>
      <PopupFactory
        type={"limit-screening"}
        visible={visible}
        onCancel={toggle}
        onSubmit={toggle}
        data={totalPertanyaan}
      />
      <ModalScreeningOption
        visible={screeningOptionModal}
        hideDialog={() => setScreeningOptionModal(false)}
        onFillScreening={() => {
          navigation.navigate("Screening", { quizId, additionalContentId });
          setScreeningOptionModal(false);
        }}
        onGoToReport={() => {
          navigation.navigate("ScreeningReport", {
            quizId,
            additionalContentId,
          });
          setScreeningOptionModal(false);
        }}
      />
    </Fragment>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 12,
  },
  content: {
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  image: {
    borderRadius: 10,
    width: "100%",
    height: 60,
  },
  title: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 13,
    lineHeight: 16,
  },
  body: {
    paddingHorizontal: 12,
    paddingTop: 16,
    paddingBottom: 12,
    gap: 10,
  },
  data: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 14,
    color: "#999",
  },
  footer: {
    paddingHorizontal: 12,
    paddingVertical: 16,
    borderTopWidth: 1,
    borderColor: "#eaeaea",
  },
  footerTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    lineHeight: 14,
    color: "#999999",
  },
  icon: {
    padding: 5,
    borderRadius: 100,
    width: 24,
    height: 24,
    backgroundColor: "#F3F3F3",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

export default SurveyCard;
