import React from "react";
import { APP_SPECIAL_PACKAGE } from "@env";
import {
  Dimensions,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import SectionContent from "./SectionContent";
import { useNavigation } from "@react-navigation/native";


function EbookCard(props) {
  const {materials, token} = props;

  const navigation = useNavigation();
  return (
    <SectionContent title="Rekomendasi Modul Materi" handleAllButton={() =>  navigation.navigate("EbookList", {additionalContentId: APP_SPECIAL_PACKAGE})}
    style={{ paddingHorizontal: 0, gap: 4}}
    headerStyle={{ paddingHorizontal: 16 }}
    >
      <ScrollView
        horizontal={true}
        // snapToInterval={Dimensions.get("window").width}
        showsHorizontalScrollIndicator={false}
        // contentContainerStyle={{ gap: 14 }}
        removeClippedSubviews={true}
        style={{ paddingHorizontal: 16 }}
      >
        {materials.map((d) => (
          <TouchableOpacity 
            key={d.eventMaterialId} 
            style={styles.container}
            onPress={() => {
              navigation.navigate("ReadEbook", {
                url: `https://fammi.ly/uploads/event_materials/document/${d?.eventMaterialId}.pdf`, 
                title: d?.title,
                materialId: d?.eventMaterialId,
                additionalContentId: APP_SPECIAL_PACKAGE,
                memberId: token
              });
            }}
            >
            <View style={styles.card}>
              <View style={styles.content}>
                <Image style={styles.thumbnail} source={{ uri: `https://fammi.ly/uploads/event_materials/images/${d?.cover}` }} />
                <Text
                  style={styles.titleTx}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {d.title}
                </Text>
                <Text
                  style={styles.authorTx}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {d.descriptions}
                </Text>
              </View>
            </View>
          </TouchableOpacity>
        ))}
        <View style={{ width: 16 }} />
      </ScrollView>
    </SectionContent>
  );
}

const styles = StyleSheet.create({
  menus: {
    flexDirection: "row",
    alignItems: "center",
    gap: 14,
  },
  container: {
    width: 148,
    height: 199,
    position: "relative",
    justifyContent:"flex-end",
    marginRight: 16
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: 10,
    padding: 12,
    width: 148,
    height: 175,
    alignItems: "center",
    overflow: "visible",
  },
  content: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 16,
    width: 124,
    zIndex: 1,
  },
  thumbnail: {
    width: 100,
    height: 140,
    borderRadius: 10,
  },
  titleTx: {
    fontFamily: "Archivo-Medium",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    marginTop: 14,
    marginBottom: 2,
    width: 124,
    textAlign: "center",
  },
  authorTx: {
    fontFamily: "Archivo-SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: 12,
    color: "#999",
    width: 124,
    textAlign: "center",
  },
});

export default EbookCard;
