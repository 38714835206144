import { Image, Pressable, StyleSheet, Text, View } from "react-native";
import { EyeIcon } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";

function ListEbook(props) {
  const navigation = useNavigation();
  const { data, additionalContentId, memberId } = props;
  return (
    <Pressable
      style={styles.listEbook}
      onPress={() => {
        navigation.navigate("ReadEbook", {
          url: `https://fammi.ly/uploads/event_materials/document/${data?.eventMaterialId}.pdf`,
          title: data?.title,
          materialId: data?.eventMaterialId,
          additionalContentId: additionalContentId,
          memberId: memberId,
        });
      }}
    >
      <Image
        source={{
          uri: `https://fammi.ly/uploads/event_materials/images/${data?.cover}`,
        }}
        style={styles.imgEbook}
      />
      <View
        style={{
          width: "72%",
        }}
      >
        <Text style={styles.textTitle} ellipsizeMode="tail" numberOfLines={1}>
          {data?.title}
        </Text>
        {data?.descriptions && data?.descriptions !== "undefined" && (
          <Text
            style={styles.textAuthor}
            ellipsizeMode="tail"
            numberOfLines={1}
          >
            {data?.descriptions}
          </Text>
        )}
        <Pressable
          onPress={() => {
            navigation.navigate("ReadEbook", {
              url: `https://fammi.ly/uploads/event_materials/document/${data?.eventMaterialId}.pdf`,
              title: data?.title,
              materialId: data?.eventMaterialId,
              additionalContentId: additionalContentId,
              memberId: memberId,
            });
          }}
          style={styles.btnRead}
        >
          <EyeIcon />
          <Text style={styles.textBtn}>Baca</Text>
        </Pressable>
      </View>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  listEbook: {
    borderRadius: 10,
    backgroundColor: "#FFF",
    padding: 12,
    marginBottom: 16,
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  imgEbook: {
    width: 70,
    height: 100,
    borderRadius: 10,
    marginRight: 12,
  },
  textTitle: {
    color: "#000",
    fontFamily: "Archivo-Bold",
    marginBottom: 4,
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: 14,
  },
  textAuthor: {
    color: "#999",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 12,
    marginBottom: 10,
  },
  btnRead: {
    flexDirection: "row",
    paddingTop: 6,
    paddingRight: 10,
    paddingBottom: 6,
    paddingLeft: 10,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderWidth: 1,
    borderColor: "#6724E2",
    borderStyle: "solid",
    backgroundColor: "#FFF",
    alignItems: "center",
    alignSelf: "flex-start",
  },
  textBtn: {
    color: "#6724E2",
    fontFamily: "Archivo-SemiBold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: 12,
    marginLeft: 6,
  },
});
export default ListEbook;
