import React, { useMemo, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Modal from "react-native-modal";
import { EmptyState } from "..";
import {
  ChatIcon,
  DangerIcon,
  PasswordCheckIcon,
  PenBold,
  StarFillIcon,
  StarMoveIcon,
} from "../../assets/icons";
import SIPPContent from "./SIPPContent";
import TipsContent from "./TipsContent";

const PopupFactory = ({
  type,
  visible,
  onCancel,
  onSubmit,
  titleMsg,
  msg,
  data,
}) => {
  const renderContent = useMemo(() => {
    switch (type) {
      case "updates":
        return (
          <EmptyState
            Icon={StarMoveIcon}
            title="Yuk Update Aplikasi Fammi"
            desc="Dapatkan fitur terbaru dan manfaat lainnya dengan memperbarui aplikasi Fammi"
          />
        );

      case "not-started":
        return <EmptyState Icon={ChatIcon} title={titleMsg} desc={msg} />;

      case "terms":
        return (
          <EmptyState
            Icon={DangerIcon}
            title="Sudah membaca syarat ketentuan?"
            desc="Dengan membeli paket ini berarti kamu sudah membaca syarat ketentuan"
          />
        );

      case "incomplete-survey":
        return (
          <EmptyState
            Icon={PenBold}
            title="Survey Belum Selesai Dijawab"
            desc="Mohon pastikan semua pertanyaan survey sudah selesai kamu isi seluruhnya"
          />
        );

      case "not-eligible":
        return (
          <EmptyState
            Icon={PenBold}
            title="Anda sudah pernah melakukan survey"
            desc="Terimakasih, Anda sudah pernah mengisi survey"
          />
        );

      case "tips":
        return <TipsContent />;
      case "sipp":
        return <SIPPContent data={data} />;
      case "limit-wa":
        return (
          <EmptyState
            Icon={PasswordCheckIcon}
            title={"Verifikasi Ditunda"}
            desc={
              "Kamu sudah mengirim ulang kode. Mohon tunggu kode masuk untuk melakukan verifikasi"
            }
          />
        );
      case "limit-screening":
        return (
          <EmptyState
            Icon={PenBold}
            title={"Kuota Screening Sudah Habis"}
            desc={`Screening ini memiliki kuota untuk ${data} orang dan saat ini sudah penuh terisi`}
          />
        );
      case "buy-package":
        return (
          <EmptyState
            Icon={StarFillIcon}
            title="Program Berbayar"
            desc="Silakan lakukan pembayaran via Admin Fammi dengan klik tombol WhatsApp di bawah ini"
          />
        );
      case "buy-package-url":
        return (
          <EmptyState
            Icon={StarFillIcon}
            title="Program Berbayar"
            desc="Silakan lakukan pembayaran melalui browser dengan klik tombol beli di bawah ini"
          />
        );
      case "join-program":
        return (
          <EmptyState
            Icon={StarFillIcon}
            title="Yakin akan gabung program?"
            desc="Program ini bersifat publik yang bisa diikuti oleh siapa pun tanpa ada syarat tertentu"
          />
        );
    }
  }, [type, msg, titleMsg]);

  const renderButton = useMemo(() => {
    switch (type) {
      case "updates":
        return {
          l1: "Nanti Aja",
          l2: "Update Sekarang",
          onSubmit,
        };
      case "not-started":
        return {
          l1: "Lihat Catatan",
          l2: "Oke",
          onSubmit,
        };
      case "terms":
        return {
          l1: "Baca Dulu",
          l2: "Lanjut Beli",
          onSubmit,
        };
      case "tips":
        return {
          l2: "Ok",
          onSubmit,
        };
      case "incomplete-survey":
        return {
          l1: "Kontak Admin",
          l2: "Oke",
          onSubmit,
        };
      case "not-eligible":
        return {
          l2: "Ok",
          onSubmit,
        };

      case "sipp":
        return {
          l1: "Tutup",
          l2: "Konsultasi",
          onSubmit,
        };

      case "limit-wa":
      case "limit-screening":
        return {
          l1: "Kontak Admin",
          l2: "Oke",
          onSubmit,
        };
      case "buy-package":
        return {
          l1: "Nanti Aja",
          l2: "Beli via Whatsapp",
          onSubmit,
        };
      case "buy-package-url":
        return {
          l1: "Nanti Aja",
          l2: "Beli Sekarang",
          onSubmit,
        };
      case "join-program":
        return {
          l1: "Nanti Aja",
          l2: "Gabung Sekarang",
          onSubmit,
        };
    }
  }, [type, onSubmit]);

  return (
    <Modal isVisible={visible} onBackdropPress={onCancel}>
      <View style={[styles.container, type === "tips" && { padding: 0 }]}>
        {renderContent}
        <View
          style={[
            styles.buttons,
            type === "tips" && { padding: 12, marginTop: 0 },
          ]}
        >
          {renderButton.l1 && (
            <TouchableOpacity
              onPress={onCancel}
              style={[
                styles.button,
                {
                  backgroundColor: "#fff",
                  borderWidth: 1,
                  borderColor: "#eaeaea",
                },
              ]}
            >
              <Text style={styles.txButton}>{renderButton.l1}</Text>
            </TouchableOpacity>
          )}
          <TouchableOpacity
            onPress={renderButton.onSubmit}
            style={[
              styles.button,
              { backgroundColor: "#6724E2" },
              type === "buy-package" && { backgroundColor: "#4EB826" },
            ]}
          >
            <Text style={[styles.txButton, { color: "#fff" }]}>
              {renderButton.l2}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    minHeight: 230,
    padding: 12,
    borderRadius: 10,
  },
  buttons: {
    flexDirection: "row",
    alignItems: "center",
    columnGap: 12,
    marginTop: 24,
  },
  button: {
    flex: 1,
    alignItems: "center",
    paddingVertical: 11,
    borderRadius: 10,
  },
  txButton: {
    fontFamily: "Archivo-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 12,
    lineHeight: 13,
  },
});

export const usePopup = () => {
  const [visible, setVisible] = useState(false);

  const toggle = () => {
    setVisible(!visible);
  };

  return {
    visible,
    toggle,
  };
};

export default PopupFactory;
