import { APP_VERSION, APP_SPECIAL_PACKAGE } from "@env";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "react-native";
import { getUrl } from "../services/shortener";

function ShortenerPage({ route }) {
  console.log("coming through");
  const navigation = useNavigation();
  
  useEffect(() => {
    if (!route.params.id) {
      return navigation.navigate("Home");
    }

    getUrl(route.params.id)
    .then((res) => {
      try {
        let url = res.data[0].url;
        url = url.split("https://app.fammi.ly/")[1];
        const parsedUrl = url.split("?")[1].split("&");
        const mappedUrl = {};
        parsedUrl.map((u) => {
          const parsedQueryString = u.split("=");
          mappedUrl[parsedQueryString[0]] = parsedQueryString[1];
        });

        navigation.navigate("quicksurvey", {
          backButton: false,
          isQuick: true,
          isScreening: "1",
          queryString: window.location.search,
          ...mappedUrl
        })
      } catch(e) {
        navigation.navigate("Home");
      }

    })
  }, []);
  
  return (
    <>
      <Text>
        
      </Text>
    </>
  );
}

export default ShortenerPage;
