import axios from "axios";
import {API_URL} from "@env";

export const getPaymentUrl = (productId) => {
    return axios.get(API_URL + `/get-content-data/payment_url/${productId}`);
}

export const timeCalculation = (scheduledTime) => {
    const diff = scheduledTime.diff(moment(), "seconds");
    const formatted = moment.utc(1000 * diff).format("DD HH:mm:ss");

    return {
        diff, 
        formatted
    };
}