import { Image, Pressable, Text, View, TextInput, Platform, StatusBar, Linking } from "react-native";
import { checkOtp, getAdditionalMember, sendOtp, updateToken } from "../services/login";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import moment from "moment";
import { getDeviceToken } from "../services/firebase";
import { PopupFactory, Snackbar } from "../components";
import { useTranslation } from "react-i18next";
import { usePopup } from "../components/PopupFactory";

function Verification({ route }) {
  const navigation = useNavigation();
  const timeLimit = 300;
  const {params} = route;
  const [code, setCode] = useState("");
  const [resend, setResend] = useState(false);
  const [wrongCode, setWrongCode] = useState(false);
  const [time, setTime] = useState(timeLimit);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const { toggle, visible } = usePopup();
  const { t } = useTranslation("verify");

  useEffect(() => {
    const theTimeoutId = setTimeout(() => {
      if (time <= 0) {
        setResend(true);
        return;
      }

      const newTime = time - 1;
      setTime(newTime);
    }, 1000);

    if (time <= 0) {
      setResend(true);
      clearTimeout(theTimeoutId);
    }
  }, [time]);

  useEffect(() => {
    let thePhone = params?.phone;
    // case 81
    if (thePhone?.substr(0, 1) !== "0") {
      thePhone = "0" + thePhone;
    }

    setPhoneNumber(thePhone)
  }, [phoneNumber]);

  return (
    <View
      style={{
        backgroundColor: "#EEEAFF",
        height: "100%",
      }}
    >
      <StatusBar backgroundColor={"#EEEAFF"}/>
      <View
        style={{
          padding: 16,
          paddingTop: 18,
        }}
      >
        <Text>
          <Image
            source={require("../assets/header-back.svg")}
            style={{ width: 36, height: 36 }}
          />
        </Text>
        <View
          style={{
            marginTop: 24,
          }}
        >
          <Text style={{fontFamily: "Archivo-Bold", fontWeight: "700", fontSize: 18, lineHeight: 24}}>{t("title1")} </Text>
          <Text style={{fontFamily: "Archivo-Bold", fontWeight: "700", fontSize: 18}}>{t("title2")}</Text>
          <Text
            style={{
              marginTop: 24,
              fontFamily: "Archivo-Regular",
              fontSize: 18, 
              fontWeight: "500"
            }}
          >
            {t("subtitle")} {params?.phone ? phoneNumber : ''}
          </Text>
          <TextInput
            style={{
              width: "100%",
              backgroundColor: "#FFF",
              textAlign: "center",
              padding: 12,
              borderRadius: 10,
              marginTop: 24,
              borderWidth: wrongCode ? 1 : 0,
              borderColor: "#FF0000"
            }}
            placeholder={t("placeholder")}
            placeholderTextColor="#999999"
            onChange={(e) => {
              setCode(e.target.value);
            }}
            onChangeText={(text) => {
              setCode(text);
            }}
          />

          <Pressable
            style={{
              backgroundColor: "#6724E2",
              color: "#FFF",
              alignItems: "center",
              padding: 12,
              borderRadius: 10,
              marginTop: 12,
            }}
            onPress={() => {
              checkOtp(params.phone, code.toLowerCase())
              .then(async (res) => {
                const response = res?.data;
                if (!response.isAuthenticate) {
                  setWrongCode(true);

                  return;
                }

                if (Platform.OS === "android") {
                  getDeviceToken()
                  .then((res) => {
                    updateToken(response.token, res)
                    .then((res) => {
                      console.log("");
                    });
                  });
                }

                await AsyncStorage.removeItem("refId");

                await AsyncStorage.setItem("token", response.token);
                await AsyncStorage.setItem("592f3e7", response.name);
                await AsyncStorage.setItem("592f3e1", phoneNumber);
                await AsyncStorage.setItem("792f3e7", "member");
                if (response.name === "waiting-verification") {
                  navigation.navigate("ProfileForm");
                  return;
                } else {
                  const memberType = response.name.split("-")[1];
                  await AsyncStorage.setItem("592f3e7", response.name.split("-")[0]);
                  await AsyncStorage.setItem("792f3e7", memberType);
                  if (memberType === "speaker") {
                    getAdditionalMember(response.token)
                    .then(async (res) => {
                      const resp = res?.data;
                      await AsyncStorage.setItem("792f3e1", resp?.additionalId);
                      return navigation.navigate("Home", {
                        newTime: new Date().getTime()
                      });
                    });
                  } else {
                    await AsyncStorage.setItem("792f3e1", "");
                    return navigation.navigate("Home", {
                      newTime: new Date().getTime()
                    });
                  }
                }
              })
            }}
          >
            <Text
              style={{
                color: "#FFF",
              }}
            >
              {t("button")}
            </Text>
          </Pressable>
          {
            resend &&
            <Text
              style={{
                marginTop: 24,
                textAlign: "center",
                color: "#6724E2"
              }}
              onPress={async () => {
                const dnes = await AsyncStorage.getItem("dnes");
                if (parseInt(dnes) > 1) {
                  return toggle();
                }
                AsyncStorage.setItem("dnes", "2");
                sendOtp(params?.phone)
                .then((res) => {
                  if (res?.data) {
                    setResend(false);
                    setTime(timeLimit);
                  }
                });
              }}
            >
              {t("resend-only")}
            </Text>  
          }
          {
            !resend &&
            <Text
              style={{
                marginTop: 24,
                textAlign: "center",
              }}
            >
              {t("resent")}&nbsp;
              {
                moment.utc(time*1000).format("mm:ss")
              }
            </Text>
          }
        </View>
      </View>
      <Snackbar
        visible={wrongCode}
        hideDialog={() => {
          setWrongCode(false);
        }}
        title={t("wrong-code")}
      />
      <PopupFactory
          type={"limit-wa"}
          visible={visible}
          onCancel={() => {
            Linking.openURL("mailto:care@fammi.ly");
            toggle();
          }}
          onSubmit={() => {
            toggle();
          }}
        />
    </View>
  );
}

export default Verification;
