import { useNavigation } from "@react-navigation/native";
import { useEffect, useState } from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";

function TodayExpertCard(props) {
  const {data} = props;
  const navigation = useNavigation();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    if (!data) {
      return;
    }

    const low = 0;
    const high = data?.length - 1;
    const randomIndex = Math.floor(Math.random() * (1 + high - low)) + low;
    setIndex(randomIndex);
  }, [data]);

  return (
    <View style={styles.container}>
      <View style={{ gap: 12, padding: 12 }}>
        <View style={styles.profile}>
          <Image
            source={{
              uri: `https://fammi.ly/uploads/speakers/images/${data?.[index].photo}`,
            }}
            style={styles.imgAva}
            height={50}
            width={50}
          />
          <View style={{ gap: 4 }}>
            <Text style={styles.txName}>{data?.[index].name}</Text>
            <Text style={styles.txDesc} ellipsizeMode="tail" numberOfLines={1}>
              {data?.[index].description}
            </Text>
          </View>
        </View>
        <Text style={styles.txDesc}>
          {data?.[index].speciality}
        </Text>
      </View>
      <View style={styles.bottomSection}>
        <TouchableOpacity
          style={[styles.button, { backgroundColor: "#DCF4CA" }]}
        >
          <Text style={[styles.txButton, { color: "#4EB826" }]}>
            Tersedia Hari Ini
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[styles.button, { backgroundColor: "#EEEAFF" }]}
          onPress={() => {
            navigation.navigate("DetailExpert", {
              speaker: data?.[index],
              type: "expert",
              status: "general"
            })
          }}
        >
          <Text style={[styles.txButton, { color: "#6724E2" }]}>
            Booking Konsultasi
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: "#fff",
    borderRadius: 10,
  },
  profile: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
  },
  imgAva: {
    height: 50,
    width: 50,
    borderRadius: 30,
  },
  txName: {
    color: "#000",
    fontFamily: "Archivo-Medium",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 500,
  },
  txDesc: {
    color: "#999",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
  },
  txDesc: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: 14,
  },
  bottomSection: {
    padding: 12,
    borderTopWidth: 1,
    borderTopColor: "#EAEAEA",
    borderStyle: "dashed",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
  },
  button: {
    flex: 1,
    height: 33,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
    paddingHorizontal: 16,
    borderRadius: 18,
  },
  txButton: {
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    // fontWeight: 600,
  },
});

export default TodayExpertCard;
