import AsyncStorage from "@react-native-async-storage/async-storage";
import { useNavigation } from "@react-navigation/native";
import { Fragment, useEffect, useState } from "react";
import {
  ActivityIndicator,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { AppBar, SurveyDetailCard } from "../components";
import PopupFactory, { usePopup } from "../components/PopupFactory";
import { checkCode, submitScreening } from "../services/screening";
import {
  calculate,
  checkEligibility,
  getDetailSurvey,
  submitSurvey
} from "../services/survey";
import md5 from "md5";

const SurveyDetailPage = ({ route }) => {
  const { params } = route;
  const [data, setData] = useState([]);
  const [answer, setAnswer] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [memberId, setMemberId] = useState(undefined);
  const [refId, setRefId] = useState(undefined);
  const navigation = useNavigation();
  const { toggle, visible } = usePopup();
  const [popupType, setPopupType] = useState("incomplete-survey");

  useEffect(() => {
    async function getMember() {
      const memberId = await AsyncStorage.getItem("token");
      setMemberId(memberId);

      const theRefId = await AsyncStorage.getItem("refId");
      setRefId(theRefId);
      return memberId;
    }

    getMember();
  }, []);

  useEffect(() => {
    getDetailSurvey(params?.quizId)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setIsLoading(false));
  }, [params]);

  return (
    <>
      <AppBar
        title={params?.isScreening === "0" ? "Survey" : "Screening"}
        isBack
        jump={params?.fromAgenda === 1 ? "AgendaProgram": params?.back ? params?.back : "Survey"}
        params={{
          additionalContentId: params?.additionalContentId,
          isScreening: params?.isScreening,
          refresh: new Date().getTime(),
          memberId: memberId
        }}
      />
      {isLoading ? (
        <View style={styles.loading}>
          <ActivityIndicator color="#6724E2" />
        </View>
      ) : (
        <Fragment>
          <View style={styles.list}>
            <ScrollView contentContainerStyle={{ gap: 16, padding: 16 }}>
              {data
                .sort((a, b) => a.orderNo - b.orderNo)
                .map((item) => (
                  <SurveyDetailCard
                    key={item.rowId}
                    data={item}
                    answer={answer}
                    handleAnswer={setAnswer}
                  />
                ))}
            </ScrollView>
          </View>
          <View style={styles.footer}>
            <View style={styles.bar}>
              <View
                style={[
                  styles.totalBar,
                  {
                    width: `${Math.ceil(
                      (Object.keys(answer).length / data?.length) * 100
                    )}%`
                  }
                ]}
              />
            </View>
            <View style={styles.footerContent}>
              <View>
                <Text style={styles.statusTx}>Status Pertanyaan</Text>
                <Text
                  style={[
                    styles.statusTx,
                    {
                      marginTop: 4,
                      fontWeight: 600,
                      fontFamily: "Archivo-SemiBold"
                    }
                  ]}
                >
                  {Object.keys(answer).length} dari {data?.length} Terjawab
                </Text>
              </View>
              <TouchableOpacity
                style={styles.button}
                onPress={() => {
                  if (data?.length > Object.keys(answer).length) {
                    return toggle();
                  }
                  const formatedAnswer = Object.keys(answer).map((i) => {
                    let answered = answer[i];
                    if (Array.isArray(answer[i])) {
                      answered = answer[i].join(":::");
                    }
                    return {
                      id: i,
                      answered: answered
                    };
                  });

                  setIsLoading(true);

                  if (params?.respId && params?.respId !== "") {
                    return submitScreening(
                      params?.quizId,
                      params?.additionalContentId,
                      params?.respId,
                      formatedAnswer
                    ).then(async (res) => {
                      await calculate(params?.quizId, memberId + "?respId="+params?.respId);

                      // checkCode(additionalContentId, quizId)
                      // .then((res) => {
                      //   const isLocked = res.data;
                      //   if (isLocked) {
                      //     return navigation.navigate(
                      //       "LockedScreening",
                            // {
                            //   quizId: params?.quizId,
                            //   additionalContentId: params?.additionalContentId,
                            //   respId: params?.respId
                            // }
                      //     );
                      //   }

                        return navigation.navigate(
                            params?.isScreening === "0" ? "SurveyResult" : "ScreeningResult",
                            {
                              quizId: params?.quizId,
                              additionalContentId: params?.additionalContentId,
                              respId: params?.respId,
                              fromAgenda: params?.fromAgenda,
                              back: params?.back
                            }
                          );
                      // });

                      // navigation.navigate("ScreeningResult", {
                      //   quizId: params?.quizId,
                      //   additionalContentId: params?.additionalContentId,
                      //   respId: params?.respId
                      // });
                    });
                  }

                  checkEligibility(
                    params?.quizId,
                    memberId,
                    params?.additionalContentId
                  ).then((res) => {
                    const resp = res?.data;
                    if (resp) {
                        return submitSurvey(
                          params?.quizId,
                          memberId,
                          formatedAnswer,
                          params?.additionalContentId + (refId && refId !== null ? `?ref_id=${refId}` : ``)
                        ).then(async (res) => {
                          await calculate(params?.quizId, memberId+(refId && refId !== null ? "?refId="+refId : ""));

                          // navigation.navigate(params?.isScreening === "0" ? "SurveyResult" : "ScreeningResult", {
                          //   quizId: params?.quizId,
                          //   additionalContentId: params?.additionalContentId,
                          //   isScreening : params?.isScreening
                          // });
                          
                          if (refId) {
                            const newRefId = md5(new Date().getTime());
                            AsyncStorage.setItem("refId", newRefId);
                            const answeredList = await AsyncStorage.getItem("answerId");
                            if (answeredList?.indexOf(params?.quizId.substring(0, 6)) < -1 || !answeredList) {
                              AsyncStorage.setItem("answerId", params?.quizId.substring(0, 6)+",");
                            }
                          }

                          checkCode(params?.additionalContentId, params?.quizId)
                          .then((res) => {
                            const isLocked = res.data;
                            if (isLocked) {
                              return navigation.navigate(
                                "LockedScreening",
                                {
                                  quizId: params?.quizId,
                                  additionalContentId: params?.additionalContentId,
                                  respId: params?.respId,
                                  fromAgenda: params?.fromAgenda,
                                  back: params?.back
                                }
                              );
                            }

                            return navigation.navigate(
                                params?.isScreening === "0" ? "SurveyResult" : "ScreeningResult",
                                {
                                  quizId: params?.quizId,
                                  additionalContentId: params?.additionalContentId,
                                  respId: params?.respId,
                                  fromAgenda: params?.fromAgenda,
                                  back: params?.back
                                }
                              );
                          });
                        });
                    } else {
                      setIsLoading(false);
                      setPopupType("not-eligible");
                      toggle();
                    }
                  });
                }}
              >
                <Text style={styles.buttonTx}>Kirim Jawaban</Text>
              </TouchableOpacity>
            </View>
          </View>
          <PopupFactory
            type={popupType}
            visible={visible}
            onCancel={toggle}
            onSubmit={toggle}
          />
        </Fragment>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  list: {
    height: "100%",
    flex: 1,
    marginTop: 1,
    paddingBottom: 6,
    backgroundColor: "#fff"
  },
  footer: {
    // position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "#fff"
  },
  bar: {
    width: "100%",
    height: 4,
    backgroundColor: "#EEEAFF"
  },
  totalBar: {
    height: 4,
    backgroundColor: "#6724E2"
  },
  statusTx: {
    fontFamily: "Archivo-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12
  },
  footerContent: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  },
  button: {
    borderRadius: 10,
    paddingVertical: 12,
    paddingHorizontal: 40,
    backgroundColor: "#6724E2"
  },
  buttonTx: {
    fontFamily: "Archivo-Bold",
    fontWeight: 700,
    color: "#fff",
    fontSize: 12
  },
  loading: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#EEEAFF"
  }
});

export default SurveyDetailPage;
