import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Dimensions,
  Image,
  Text,
  Pressable,
  Linking,
  TouchableOpacity,
  Platform,
} from "react-native";
import { ChevronRight } from "../assets/icons";
import { useNavigation } from "@react-navigation/native";
// import { usePopup } from "./PopupFactory";
import { setProgram } from "../services/programs";
import {PopupFactory} from "../components";
import { usePopup } from "./PopupFactory";
import { getPaymentUrl } from "../services/payment_url";

function ChoosenProgram({ titleCta, data, isRecommendation, setIsAddProgramVisible, setLoadProgram, setSnackBarMsg, setShowSnackBar, token, makeFull, selective}) {
  const { toggle, visible } = usePopup();
  const [isCoverError, setIsCoverError] = useState(false);
  const [popupType, setPopupType] = useState("join-program");
  const [linkUrl, setLinkUrl] = useState("");
  const navigation = useNavigation();

  useEffect(() => {
    if (!data || (data?.productType !== '18' && data?.productType !== '19')) {
      return;
    }

    getPaymentUrl(data?.productId)
    .then((res) => {
      const contents = res?.data;
      if (contents) {
        setLinkUrl(JSON.parse(contents[0]?.contents));
      }
    });
  }, [data]);

  let maxWidth = 0;
  if (Platform.OS !== "web") {
    maxWidth = makeFull ? Dimensions.get("window").width - 30 : Dimensions.get("window").width - 100;
  } else  {
    maxWidth = makeFull ? "370px" : "300px" ;
  }

  // console.log(maxWidth);

  return (
    <TouchableOpacity 
      style={{...styles.parentCard, width: maxWidth}}
      onPress={() => {
        if (!isRecommendation || isRecommendation === "false") {
          navigation.navigate("ProgramDetail", {
            program: data
          });
          return true;
        }

        if (data.productType === "13") {
          return setIsAddProgramVisible(true);
        }

        if (data.productType === "12") {
          setPopupType("join-program");
          toggle();
          return true;
        }

        if (data.productType === "18") {
          setPopupType("buy-package");
          toggle();
          return true;
        }

        if (data.productType === "19") {
          setPopupType("buy-package-url");
          toggle();
          return true;
        }
      }}
      >
      <Image
        source={{
          uri: !isCoverError ? `https://fammi.ly/uploads/products/images/mobile_banner_${data?.productImage}?v=${new Date().getTime()}` : `https://fammi.ly/uploads/products/images/${data?.productImage}?v=${new Date().getTime()}`,
        }}
        onError={() => {
          setIsCoverError(true);
        }}
        style={styles.imageCover}
      />
      <View style={styles.mainContent}>
        <Text style={styles.textTitle} numberOfLines={1}>{data?.productName}</Text>
        <Text style={styles.textMain} numberOfLines={1}>
          {
            data?.shortDesc !== "" ? data?.shortDesc : priceDescription
          }
        </Text>
      </View>
      <Pressable
        onPress={() => {
          if (!isRecommendation || isRecommendation === "false") {
            navigation.navigate("ProgramDetail", {
              program: data
            });
            return true;
          }

          if (data.productType === "13") {
            return setIsAddProgramVisible(true);
          }

          if (data.productType === "12") {
            setPopupType("join-program");
            toggle();
            return true;
          }

          if (data.productType === "18") {
            setPopupType("buy-package");
            toggle();
            return true;
          }

          if (data.productType === "19") {
            setPopupType("buy-package-url");
            toggle();
            return true;
          }
        }}
        style={styles.bottomContent}
      >
        <Text style={styles.textPrimary}>
          {
            (data.productType === "13" || data.productType === "12") &&
            titleCta === "" &&
            "Gabung Program"
          }
          {
            (data.productType === "18") &&
            titleCta === "" &&
            "Beli Via Admin"
          }
          {
            (data.productType === "19") &&
            titleCta === "" &&
            "Beli Sekarang"
          }
          {titleCta !== "" && titleCta}
        </Text>
        <ChevronRight />
      </Pressable>

      <PopupFactory
        type={popupType}
        visible={visible}
        onCancel={toggle}
        onSubmit={() => {
          if (data?.productType === "18" || data?.productType === "19") {
            if (linkUrl === "") {
              Linking.openURL("https://wa.me/6289655110896");
            } else {
              Linking.openURL(linkUrl.payment_url);
            }
            toggle();
            return;
          }

          setProgram(data?.additionalContentId, 12, token)
          .then((res) => {
            if (res?.data === true) {
              setLoadProgram(true);

              setSnackBarMsg("Program berhasil ditambahkan");
              setShowSnackBar(true);
              return;
            }
      
            setIsError(true);
            setSnackBarMsg("Program gagal ditambahkan. Silahkan coba lagi.");
            setShowSnackBar(true);
          })
          toggle();
        }}
      />
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  parentCard: {
    borderRadius: 10,
    backgroundColor: "#FFFFFF",
    marginRight: 16,
    // height: 214
  },
  imageCover: {
    height: 100,
    width: "100%",
    borderRadius: 10,
  },
  mainContent: {
    padding: 12,
  },
  bottomContent: {
    padding: 12,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopColor: "#eaeaea",
    borderTopWidth: 1,
    borderStyle: "solid"
  },
  textTitle: {
    color: "#000",
    fontFamily: "Archivo-Bold",
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "700",
  },
  textMain: {
    color: "#000",
    fontFamily: "Archivo-Regular",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: 18,
    marginTop: 6,
  },
  textPrimary: {
    color: "#6724E2",
    fontFamily: "Archivo-Bold",
    fontSize: 12,
    fontStyle: "normal",
    fontWeight: "600",
  },
});
export default ChoosenProgram;
